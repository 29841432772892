.projectBody {
  width: 100%;
  padding: 2rem;
}
.header {
  display: none;
}

.name {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
}

.name a:link,
.name a:visited {
  color: black;
}

.back {
  cursor: pointer;
  display: none;
}

.backMobile {
  cursor: pointer;
}

.backMobile a:link,
.backMobile a:visited,
.back a:link,
.back a:visited {
  color: black;
}

h1 {
  font-size: 1.7rem;
  padding: 40px 0 20px;
}

.projectData {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  margin-left: 0.5rem;
  padding: 1rem 0 0;
}
.projectStack {
  display: flex;

  font-size: 0.9rem;
  text-transform: uppercase;
}

.projectLinks {
  font-size: 1rem;
  font-weight: 600;
}

.projectLinks a {
  text-decoration: underline solid 1px;
}

.projectLinks:nth-of-type(3) {
  padding-top: 3rem;
}

h2 {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 140%;
  width: 95%;
  max-width: 880px;
  margin: 2rem 0 0;
}

.iconContainer {
  display: flex;
  margin: auto 0 0;
}

.iconContainer svg {
  height: 1.8rem;
  width: auto;
  padding: 0 8px 0 0;
}

.footer {
  padding-top: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.description {
  width: 90%;
  max-width: 715px;
  margin-top: 2rem;
  line-height: 140%;
}

.description img {
  max-width: 90%;
  margin-top: 2rem;
}

.syntax {
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.caption {
  margin-top: 4px;
  font-size: 0.9rem;
}

.doublePics {
  font-size: 0.8rem;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.doublePics img {
  max-width: 90%;
}

@media screen and (min-width: 568px) {
  .projectBody {
    width: 100%;
    padding: 5rem 6rem;
  }

  .back {
    cursor: pointer;
    display: block;
  }

  .backMobile {
    cursor: pointer;
    display: none;
  }

  .header {
    padding-bottom: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .name {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .name a:link,
  .name a:visited {
    color: black;
  }

  h1 {
    font-size: 2rem;
    padding: 40px 0 20px;
  }

  .projectData {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-left: 0.5rem;
    padding: 1rem 0 0;
  }
  .projectStack {
    display: flex;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .projectLinks {
    font-size: 1rem;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 140%;
    width: 88%;
    max-width: 880px;
    margin: 2rem 0 0;
  }

  .iconContainer {
    display: flex;
    margin: auto 0 0;
  }

  .iconContainer svg {
    height: 1.8rem;
    width: auto;
    padding: 0 8px 0 0;
  }

  .description {
    width: 80%;
    max-width: 715px;
    margin-top: 2rem;
    line-height: 140%;
  }

  .description img {
    width: 400px;
    margin-top: 2rem;
  }

  .caption {
    margin-top: 4px;
    font-size: 0.9rem;
  }

  .doublePics {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }

  .doublePics img {
    width: 420px;
  }

  .footer {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
