* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

@font-face {
  font-family: "OnsiteRegular"; /*Can be any text*/
  src: local("OnsiteStandard"),
    url("./fonts/OnsiteStandardTRIAL-Regular.otf") format("otf");
}

:root {
  scroll-behavior: smooth;
  --yellow: #edd139;
  --anotheryellow: #ffd500;
  --whitish: #f1efe9;
  --grey: #f5f0ea;
  --green: #d7e10b;
  --orange: #df9504;
  --neonyellow: #f0f208;
  --brown: #2a0000;
  --green: #55ec80;
  --oat: #edebdc;
  --orange: #ff4500;
  --blue: #0000ff;
  --morado: #551a8b;
}

body {
  background-color: var(--whitish);
  margin: 0;
  font-family: Inter, "Courier New", Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 4px;
  background-color: var(--whitish);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--brown);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  display: none;
}

/* Sections -- this is used in Intro/Hello*/

.centered {
  display: flex;
  align-items: top;
}

/* Hello Styles */

.left-right {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bottom {
  padding-bottom: 11rem;
}

.section--hello {
  background-color: var(--oat);
  height: auto;
}

ul {
  list-style: none;
  text-transform: uppercase;
  line-height: 1.5rem;
}

p {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 2em;
}

/* Timeline Styles */

.section--journey--mobile {
  font-family: "Azeret Mono", monospace;
  background-color: #55ec80;
}

.section--journey {
  font-family: "Azeret Mono", monospace;
  background-color: #55ec80;
  display: none;
}

main {
  overflow-x: hidden;
  display: flex;
  position: sticky;
  top: 0;
  gap: 5rem;
  z-index: 500;
}

/*//////////*/

/* .timeline--events {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 80px;
  padding: 0 3rem 64px;
} */

.journey,
.continue {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
}

.journey {
  display: block;
  position: absolute;
  top: 80px;
  left: 50px;
}

/* Project Styles */

.section--project {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}

.section--header {
  padding: 25px 60px 20px 50px;
  border-bottom: 1px solid beige;
  background-color: #f2fc08;
  color: #df9504;
}

.header--title {
  font-weight: 300;
  text-align: center;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.9rem;
}

/* Contact Styles */

.section--contact {
  height: 100vh;
  background: var(--green);
  gap: 16px;
  transition: all 4s;
}

.section--contact.active {
  background: var(--yellow);
  gap: 16px;
}

a {
  text-decoration: none;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 568px) {
  nav {
    display: block;
  }

  nav ul li a:visited,
  a:focus {
    text-decoration: none;
    color: white;
  }

  nav ul li a.active {
    color: black;
  }

  nav ul li a:hover {
    color: black;
  }

  /* Sections */

  .hundredvh {
    width: 100%;
    min-height: 100vh;
    max-width: auto;
  }

  .centered {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  /* Hello Styles */

  .left-right {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .bottom {
    padding-bottom: 11rem;
  }

  .section--hello {
    background-color: var(--oat);
    /* position: relative; */
  }

  ul {
    list-style: none;
    text-transform: uppercase;
    line-height: 1.5rem;
  }

  p {
    font-weight: 400;
    font-size: calc(11px + 0.7vw);
    line-height: 2em;
    margin-bottom: 8px;
  }

  /* Timeline Styles */

  .section--journey--mobile {
    font-family: "Azeret Mono", monospace;
    background-color: #55ec80;
    display: none;
  }

  .section--journey {
    font-family: "Azeret Mono", monospace;
    background-color: #55ec80;
    display: block;
  }

  main {
    overflow-x: hidden;
    display: flex;
    position: sticky;
    top: 0;
    margin-left: 5vw;
    gap: 96px;
    z-index: 500;
  }

  /*//////////*/

  /*   .timeline--events {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    display: flex;
    gap: 80px;
    padding: 0 3rem 64px;
  } */

  .journey,
  .continue {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
  }

  .journey {
    display: block;
    position: absolute;
    top: 80px;
    left: 50px;
  }

  /* Project Styles */

  .snap-container {
    /* scroll-snap-type: y mandatory; */
  }

  .section--project {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .section--header {
    padding: 25px 60px 20px 50px;
    border-bottom: 1px solid beige;
    background-color: #f2fc08;
    color: #df9504;
  }

  .header--title {
    font-weight: 300;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 1rem;
  }

  /* Contact Styles */

  .section--contact {
    background: var(--green);
    /* background-color: var(--yellow); */
    gap: 16px;
    transition: all 4s;
  }

  .section--contact.active {
    background: var(--yellow);
    gap: 16px;
  }

  a {
    text-decoration: none;
  }

  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: black;
  }
}
