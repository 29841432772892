.headerStatic {
  position: static;
  width: 100%;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  background-color: transparent;
}

.name {
  text-align: center;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 600;
  align-self: center;
}

.name a:link,
.name a:visited {
  color: black;
}

@media screen and (min-width: 568px) {
  /* Fixed Header */
  .headerStatic {
    width: 100%;
    padding: 5rem 6rem 0 6rem;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    z-index: 900;
  }

  .name {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .name a:link,
  .name a:visited {
    color: black;
  }

  /* .navMain {
    pointer-events: none;
  } */
}
