:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.1),
    0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.1),
    1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.11),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.11),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.11),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.11);
  --shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.1),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.1),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.1),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.1),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.1),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.1),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.1),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.1);
}

/* Mobile journey styles */

.main {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
}

.title {
  margin-top: 6rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 16px;
}

.timelineImg {
  width: 300px;
  height: 300px;
  margin: -3rem auto 0;
  border-radius: 100%;
  background-image: url("/public/images/compu.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  transition: all 2s;
  cursor: none;
  animation-name: background-pic;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes background-pic {
  0% {
    background-image: url("/public/images/compu.jpg");
  }

  50% {
    background-image: url("/public/images/tenis.jpg");
  }

  100% {
    background-image: url("/public/images/compu.jpg");
  }
}

.timelinePhotoTitle {
  width: 75%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.timelineItem {
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.timelineTitle {
  font-family: "Azeret Mono", monospace;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  max-width: 352px;
}

.timelineDescription {
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
}

.hiddenItem {
  opacity: 0;
}

/* end mobile projects */

.projectsHeader {
  text-transform: uppercase;
  padding: 2rem;
}

.projectsGrid {
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.topContainer:hover {
  z-index: 1000;
}

.projectCard {
  max-width: 75vw;
  flex: 1;
  position: relative;
  border: #2a0000 1px solid;

  background-color: var(--oat);
  opacity: 90%;
  border-radius: 24px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 100;
}

.projectCard:hover {
  background-color: var(--oat);
  opacity: 100%;
}

.projectCard:hover .tooltiptext {
  font-size: 9px;
}

.projectCard:hover .project--stack {
  display: none;
}

.projectImg {
  height: auto;
  position: relative;
}

.projectImg img {
  width: 100%;
  height: auto;
}

.gifCanvas {
  width: 400px;
}

.projectData {
  font-family: Inter;
  color: black;
  padding: 16px 16px 24px;
}

.projectTitle {
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: 12px;
}

.projectDescription {
  font-size: 0.9rem;
}

.projectStack {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  background-color: var(--brown);
  color: var(--whitish);
  padding: 6px;
  text-transform: uppercase;
}

.iconContainer {
  display: flex;
  margin: auto 0 0;
}

.iconContainer svg {
  height: 1.5rem;
  width: auto;
  padding: 0 8px 0 0;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--brown);
  color: whitesmoke;
  text-align: center;
  padding: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  font-size: 1rem;
}

.projectLinks {
  font-size: 0.9rem;
  padding-top: 12px;
}

.projectLinks a {
  text-decoration: underline 1px black;
}

@media screen and (min-width: 568px) {
  .projectsHeader {
    text-transform: uppercase;
    padding: 7rem 6rem 4.5rem;
  }

  .projectsGrid {
    padding: 0 30px 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 20px;
  }

  .topContainer:hover {
    z-index: 1000;
  }

  .projectCard {
    max-width: 25vw;
    flex: 1;
    position: relative;
    border: #2a0000 1px solid;
    background-color: var(--oat);
    opacity: 90%;
    border-radius: 24px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 100;
  }

  .projectCard:hover {
    background-color: var(--oat);
    opacity: 100%;
    box-shadow: var(--shadow-elevation-high);
    z-index: 1000;
  }

  .projectCard:hover .tooltiptext {
    font-size: 10px;
  }

  .projectCard:hover .project--stack {
    display: none;
  }

  .projectImg {
    height: auto;
    position: relative;
  }

  .projectImg img {
    width: 100%;
    height: auto;
  }

  .gifCanvas {
    width: 400px;
  }

  .projectData {
    font-family: Inter;
    color: black;
    padding: 16px 16px 20px;
  }

  .projectTitle {
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 12px;
  }

  .projectDescription {
    font-size: 0.9rem;
  }

  .projectStack {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.9rem;
    background-color: var(--brown);
    color: var(--whitish);
    padding: 6px;
    text-transform: uppercase;
  }

  .iconContainer {
    display: flex;
    margin: auto 0 0;
  }

  .iconContainer svg {
    height: 1.5rem;
    width: auto;
    padding: 0 8px 0 0;
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: var(--brown);
    color: whitesmoke;
    text-align: center;

    padding: 8px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    font-size: 1rem;
  }
}
